/*
3.PAGES
└── 3.6. MY ACCOUNT PAGE
    ├── 3.6.1 MY INFORMATION
    ├── 3.6.2 PAYMENTS
    ├── 3.6.4 ACCOUNT HISTORY
    ├── 3.6.5 BET HISTORY
    ├── 3.6.6 CASINO REPORT
    ├── 3.6.7 MY LIMITS
    └── 3.6.8 GOOGLE AUTHORIZATION
*/

/*
|--------------------------------------------------------------------------
|  == 3.6. MY ACCOUNT PAGE
|--------------------------------------------------------------------------
*/

.my-account--desktop,
.my-account--tablet,
.my-account--mobile {
    background: #121A1D;

    @extend %G-my-account;

    .bet-delete-icon {
        color: #bebebe;
    }
}

.my-account--tablet,
.my-account--mobile {
    @extend %G-my-account--mobile;

    .my-account-section {
        border: 1px solid #2F3F45;
    }

    .my-account-section__title {
        background: #2F3F45;
        color: #fff;
    }

    .my-account-section--secondary {
        .my-account-section__title {
            background: #22333A;
        }
    }
}

.my-account__title--mobile {
    @extend %G-my-account__title--mobile;

    background: #22333A;
    color: #FFB400;

    .my-account__title-back-icon {
        color: #FFB400;
    }
}

.my-account--tablet,
.my-account--desktop {
    @extend %G-my-account--tablet-desktop;
}

.my-account--tablet {
    @extend %G-my-account--tablet;

    .my-account__title {
        background: #3D4F53;
        color: #FFB400;
    }
}

.my-account--desktop {
    @extend %G-my-account--desktop;
    background: transparent;

    .account-menu_link-icon {
        fill: rgba(0, 0, 0, 0.65);
    }

    .my-account__title {
        background: #22333A;
        color: #FFB400;
    }
}

.my-account-drawer {
    @extend %G-my-account-drawer;

    .my-account-drawer__blocked-user {
        color: #FFB400;
    }

    .my-account-drawer__menu-list {
        background: #121A1D;
    }

    .my-account-drawer__menu-link {
        color: #fff;

        &.active {
            color: #FFB400;
        }
    }
}

.my-account-info-list {
    @extend %G-my-account-info-list;

    .my-account-info-list__item {
        color: #fff;
        background: #495E64;

        &:nth-child(2n) {
            background: #3C535A;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #2F3F45;
        }
    }

    .my-account-info-list__item-value--negative {
        color: #f16969;
    }
}

.body--with-cursor {
    .bet-delete-icon:hover {
        color: #fff;
    }

    .my-account-drawer__close-btn:hover {
        color: #fff;
    }

    .my-account-drawer__menu-link:hover {
        color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.1 MY ACCOUNT PAGE -> MY INFORMATION
|--------------------------------------------------------------------------
*/

.my-account--tablet,
.my-account--mobile {
    .my-info {
        @extend %G-my-info--mobile;

        .my-info__change-pass {
            color: #FFB400;
        }
    }
}

.my-info__wrapper--mobile {
    @extend %G-my-info__wrapper--mobile;
}

.my-info__wrapper--tablet {
    @extend %G-my-info__wrapper--tablet;
}

.my-info__wrapper--desktop {
    @extend %G-my-info__wrapper--desktop;

    .my-info__coll {
        background: #3D4F53;
    }

    .my-info__sub-title {
        background: #2F3F45;
        color: #FFB400;
    }

    .my-info__row {
        border-bottom: 1px solid #121A1D;
        color: #fff;

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.body--with-cursor {
    .my-info__cell-link:hover {
        text-shadow: 0 0 7px #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.2 PAYMENTS
|--------------------------------------------------------------------------
*/
.payments {
    @extend %G-payments;

    .payments__icon {
        color: #fff;
    }

    .payment__btn-link-text {
        color: #fff;
    }
    .payment__btn-link-name {
        color: #FFB400;
    }
}

.body--with-cursor {
    .ll-btn2:hover .payment__btn-link-name {
        color: #000;
    }
}

.payments__content--mobile,
.payments__content--desktop {
    @extend %G-payments-btn-link;

    .payment {
        box-shadow: 3px 2px 5px #00000038;
        background-color: #fff;
    }
}

.payments__content--mobile {
    .payment {
        border: 1px solid #32484F;
    }
}

.payments__content--desktop {
    .payments__no-data {
        background: #3D4F53;
        color: #fff;
    }

    .payment {
        border: 1px solid #32484F;
    }
}

.payment-modal {
    @extend %G-payment-modal;
}

/*
|--------------------------------------------------------------------------
| == 3.6.4 ACCOUNT HISTORY
|--------------------------------------------------------------------------
*/

.account-report--mobile {
    @extend %G-account-report--mobile;

    border: 1px solid #1D272E;
    background: #2F3F45;
    color: #fff;

    .account-report-item--active:not(:last-child),
    .account-report-item:not(:last-child) {
        border-right: 1px solid #1D272E;
    }

    .account-report-item--active {
        background: #1D272E;
        color: #FFB400;
    }

    .ll-burger-icon--opened {
        div {
            background-color: #FFB400;
        }
    }

    & + .my-account-info-list {
        border: 1px solid #2F3F45;
        border-top: none;
    }
}

.account-report--tablet,
.account-report--desktop {
    @extend %G-account-report--desktop;

    .account-report-item {
        background: #3D4F53;
    }

    .my-account-info-item__content--overflow {
        background: #3D4F53;

        &.expanded {
            box-shadow: 0 2px 8px 0px #0000002b;
        }
    }

    .my-account-info-item__expand-panel--icon {
        color: #fff;
    }

    .my-account-info-item__title {
        background: #2F3F45;
        color: #fff;
    }

    .my-account-info-list__item {
        border-bottom: 1px solid #121A1D;
        color: #fff;
    }

    .my-account-info-list__item-value--negative {
        color: #f58c89;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.5 BET HISTORY
|--------------------------------------------------------------------------
*/
.bet-history--mobile,
.bet-history--tablet {
    @extend %G-bet-history--mobile-tablet;

    .status-filter-switcher-switch-base {
        color: #fff !important;
    }

    .bet-history__status-active-bets--disabled,
    .bet-history__status-filter--disabled {
        color: #C5C5C5;

        .ll-dropdown-menu__icon {
            color: #C5C5C5;
        }
    }

    .status-filter-switcher-track {
        background-color: #000 !important;
        opacity: 0.38!important;
    }

    .bet-history__status-filter {
        .bet-history__status-filter-dropdown {
            background: #32484F;
            border: 1px solid #22333A;

            .ll-dropdown-menu-item,
            .ll-dropdown-menu-item--disabled,
            .ll-dropdown-menu-item--active {
                color: #fff;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #22333A;
                }
            }

            .ll-dropdown-menu-item--disabled {
                color: #979797;
            }

            .ll-dropdown-menu-item--active {
                background: #22333A;
                color: #FFB400;
            }
        }
    }
}

.bet-history--tablet {
    @extend %G-bet-history--tablet;
}

.bet-history--desktop {
    @extend %G-bet-history--desktop;

    .bet-history__cashout-cell-btn {
        background: #F6A83C;
        color: #000;
    }

    /* ---------------------- Bet details in table ---------------------- */

    .bet-history-detail__info {
        background-color: #3c535a;
        color: #e6e6e6;
    }

    .bet-history-detail__info-row {
        border: 1px solid #121a1d;
        border-top: none;
    }

    .bet-history-detail__info-td {
        &:not(:last-child)::after {
            background-color: #ffffff8a;
        }
    }
}

.body--with-cursor {
    .bet-history__cashout-cell-btn:hover {
        background: #F0871A;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.6 CASINO REPORT
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.6.7 MY LIMITS
|--------------------------------------------------------------------------
*/

.my-limits {
    @extend %G-my-limits;

    .my-limits__provider-limit-info-icon {
        color: #ffb400;
    }
}

.my-limits--mobile,
.my-limits--tablet {
    @extend %G-my-limits--mobile-table;

    .my-limits__content-box--rows .my-limits__content-box-title {
        background-color: #2f3f45;
        color: #fff;
    }

    .my-limits__content-box--info .my-limits__content-box-title {
        color: #ffb400;
    }

    .my-limits__content-box-wrp {
        color: #fff;
    }

    .my-limits__content-row {
        &:nth-child(2n + 1) {
            background-color: #495e64;
        }

        &:nth-child(2n) {
            background-color: #3c535a;
        }
    }
}

.my-limits--desktop {
    @extend %G-my-limits--desktop;

    .my-limits__content-box {
        box-shadow: 0 0 5px 0 #00000020;
        color: #fff;
    }

    .my-limits__content-box-title {
        background: #2f3f45;
    }

    .my-limits__content-box-wrp {
        background-color: #3d4f53;
    }

    .my-limits__content-row {
        &:not(:last-child) {
            border-bottom: 1px solid #121a1d;
        }

        & > * {
            &:first-child {
                border-right: 1px solid #121a1d;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.8 MY ACCOUNT PAGE -> GOOGLE AUTHORIZATION
|--------------------------------------------------------------------------
*/
.google-auth {
    @extend %G-google-auth;

    .google-auth__content {
        border: 1px solid #BEBEBE;
        background: #3D4F53;
        color: #fff;
    }

    .google-auth__img-qr {
        @include size(
            $size-width--cabinet-google-auth-img + px,
            $size-height--cabinet-google-auth-img + px
        );
    }

    .google-auth__qr-img-container {
        border-bottom: 1px solid #BEBEBE;
    }

    .google-auth__install-app {
        border-bottom: 1px solid #BEBEBE;
    }

    .google-auth__form-password,
    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password;
    }

    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password--mobile;
    }

    .google-auth__form-qr-code,
    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code;
    }

    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code--mobile;
    }
}

.my-account--mobile .google-auth {
    @extend %G-google-auth--mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.6.9 MY ACCOUNT PAGE -> MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    @extend %G-messages;

    .message-item {
        background-color: #3d4f53;
        color: #fff;
    }

    .message-date {
        color: #ffffff8a;
    }

    .message-unread {
        background-color: #2f3f45;
    }
}

.messages--mobile,
.messages--tablet {
    @extend %G-messages--mobile;
}

.messages--desktop {
    @extend %G-messages--desktop;
}