/*
3.PAGES
└── 3.1.2. LIVE PAGE
    ├── 3.1.2.1. LIVE WIDGETS
    ├── 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
    ├── 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
    ├── 3.1.2.4. LIVE PAGE (MOBILE)
    ├───├── 3.1.2.4.1. LIVE PAGE MENU
    ├───└── 3.1.2.4.2. LIVE PAGE DETAILS
    └── 3.1.2.5. LIVE CALENDAR
*/

/*
|--------------------------------------------------------------------------
| == 3.1.2.1. LIVE WIDGETS
|--------------------------------------------------------------------------
*/

.live-sticky-widget {
    top: calc(#{$size-height--header_mobile} + #{$size-height--header-nav-menu_mobile});

    @include mq('tablet') {
        top: calc(#{$size-height--header_tablet} + #{$size-height--header-nav-menu_tablet});
    }
}

/* --------------- Live widgets navigation tabs --------------- */
.live-widgets__nav--mobile {
    background-color: #22333A;

    .live-widgets__nav-item {
        color: #fff;
    }
}

.live-widgets__nav--tablet,
.live-widgets__nav--desktop {
    background-color: #22333A;

    .live-widgets__nav-item {
        color: #fff;

        .live-widgets__nav-icon {
            color: #fff;
        }
    }

    .live-widgets__nav-item--active {
        color: #FFB400;

        .live-widgets__nav-icon {
            color: #FFB400;
        }
    }

    .live-widgets__nav-info {
        color: #fff;

        &:hover {
            color: #FFB400;
        }
    }
}

.live-widgets__nav--desktop {
    .live-widgets__nav-item:hover {
        color: #FFB400;

        .live-widgets__nav-icon {
            color: #FFB400;
        }
    }
}

.live-widgets__nav--tablet {
    border-bottom: 1px solid #fff;
}

.live-widgets {
    .live-widgets__nav-info-text {
        border: 1px solid #5B7177;
        background-color: #1D2A2E;
        color: #FFFFFF;
    }
}

.prematch--mobile {
    .live-widgets__nav-container {
        border: 0.1rem solid #1C1C1C;
        box-shadow: 3px 2px 5px #1C1C1C;
        background-color: #2F3F45;

        .live-widgets__nav-item {
            border-right: 1px solid #1C1C1C;
            background-color: #2F3F45;
        }

        .live-widgets__nav-burger,
        .live-widgets__nav-burger--opened {
            background-color: #2F3F45;

            div {
                background-color: #fff;
            }
        }

        .live-widgets__nav-info {
            background: #2F3F45;
            color: #fff;
        }
    }
}

/* ------------ Live widgets navigation tabs END ------------ */

/* -------------------- Stream widget -------------------- */
.stream {
    .stream__loader-box {
        background: #2d2d2d;
    }

    .stream__error-content {
        background: #2d2d2d;
    }

    .stream__error-text {
        color: #fff;
    }
}

.livematches__view-buttons {
    color: #BEBEBE;
    background-color: #22333A;
    padding: 0;
}

.livematches__view-button,
.livematches__view-button--active {
    width: 6rem!important;
    padding: 0 2rem;
    height: 100%;
}

.livematches__view-button--active {
    background-color: #324A53;
    color: #FFB400;
    width: 24rem!important;
}

.prematch--desktop {
    .livematches__view-button:hover {
        background-color: #324A53;
        color: #FFB400;
    }
}

/* ------------------- Stream widget END ------------------- */


/*
|--------------------------------------------------------------------------
| == 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/

.live-menu__loading {
    background-color: rgba(0, 0, 0, 0.2);
}

.live-menu__section {
    min-width: $size-width__livematch-menu--desktop-1360;
    max-width: $size-width__livematch-menu--desktop-1360;

    @include mq('(min-width: 1500px)') {
        min-width: $size-width__livematch-menu--desktop-1500;
        max-width: $size-width__livematch-menu--desktop-1500;
    }

    @include mq('(min-width: 1700px)') {
        min-width: $size-width__livematch-menu--desktop-1700;
        max-width: $size-width__livematch-menu--desktop-1700;
    }

    & + .prematch__loading--initial {
        background-color: #1C1C1Cdd;
    }

    &.live-menu__section--disabled:before {
        background: #1C1C1C50;
    }

    .live-left-menu__odds-count {
        background-color: #000;
    }

    .ll-accordion .ll-accordion__header,
    .ll-accordion--active .ll-accordion__header {
        background-color: #324A53;
    }

    .live-common-menu__item-name {
        border-bottom: 1px solid #193139;
        background-color: #3e4f54;
        color: #fff;
    }

    .ll-accordion__header {
        border-bottom: 1px solid #1c1c1c;
    }

    .ll-accordion__header-count {
        color: #fff;
        background-color: #5B7177;
    }

    .ll-accordion__header-text {
        color: #fff;
    }

    .live-left-menu__item {
        background-color: #1d2a2e;
        color: #fff;
    }

    .match__icon--cant-cashout {
        opacity: .2;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        &:not(:first-of-type) {
            box-shadow: inset 0 1px 0 0 #193139;
        }

        &:first-of-type:last-of-type {
            box-shadow: inset 0 -1px 0 0 #193139;
        }

        &:not(:first-of-type):last-of-type {
            box-shadow:
                    inset 0 1px 0 0 #193139,
                    inset 0 -1px 0 0 #193139;
        }

        .live-left-menu__bet {
            box-shadow: inset -1px 0 0 0 #193139;
        }

        .live-left-menu__bet--active {
            background: #FFB400 !important;
            color: #000;

            .live-left-menu__bet-value:after {
                color: #000;
            }
        }
    }

    .live-match__competitors-holder {
        box-shadow: inset -1px 0 0 0 #193139;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        .live-match__info-header-item {
            color: #bebebe;
        }
    }

    .live-left-menu__bet {
        box-shadow: inset 1px 0 0 0 #193139, inset -1px 0 0 0 #193139;
    }

    .live-left-menu__row-header {
        color: #BEBEBE;
        background-color: #151f22;
    }

    .live-left-menu__row-header-competitors {
        box-shadow: inset -1px 0 0 0 #193139;
    }

    .live-left-menu__oddsv {
        box-shadow: inset 1px 0 0 0 #193139, inset -1px 0 0 0 #193139;
    }

    .live-left-menu__header {
        background-color: #22333A;
        color: #FFB400;
    }

    .live-left-menu__bet--dn {
        .live-left-menu__bet-value:after {
            color: #d10307;
        }
    }

    .live-left-menu__bet--up {
        .live-left-menu__bet-value:after {
            color: #abf210;
        }
    }
}

.body--with-cursor {
    .live-common-menu__item-name:hover {
        background-color: #233840;
    }
}

.prematch--desktop {
    .ll-accordion__header:hover {
        background-color: #FFB400;
        .ll-accordion__header-text {
            color: #000;
        }

        .ll-accordion__header-count {
            color: #fff;
        }
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        .live-left-menu__bet:hover {
            background: #f5782c !important;
            color: #000;

            .live-left-menu__bet-value:after {
                color: #000;
            }
        }

        &:hover {
            background: #FFB400;
            color: #000;

            .live-match__info-header-item {
                color: #bebebe;
            }

            .live-left-menu__bet-value:after {
                color: #000;
            }
        }
    }

    .live-left-menu__list-row--active {
        background: #FFB400;
        color: #000;

        .live-left-menu__bet {
            .live-left-menu__bet-value:after {
                color: #000;
            }
        }
    }
}

.prematch--tablet {
    .live-left-menu__view-btn,
    .live-details__calendar-btn {
        background-color: #233840;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/
.live-detail__section {
    .match-details__header-info-competitor:after {
        background-color: #000000;
    }

    .match-details__header-info {
        background-color: #999999;
        color: #fff;
    }

    .match-details__competitor-without-img {
        .match-details__breadcrumbs-icon {
            color: white
        }

        .match-details__competitor-without-img-icon::before {
            color: #fff;
        }
    }

    .match-details__competitor-name {
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
    }

    .match-details__description {
        color: #fff;
    }
}

.live-market-groups__slider {
    background-color: #121A1D;
    border-bottom: 1px solid #393939;

    .market-groups__slider-arrow {
        color: #fff;
    }

    .live-match-details__market-group,
    .live-match-details__market-group--active {
        color: #fff;
    }

    .live-match-details__market-group--active {
        background-color: #F5782C;
    }
}

.prematch--desktop {
    .live-match-details__market-group:hover {
        @include mq('desktop') {
            background-color: #F5782C;
        }
    }
}

.live-match-details {
    color: #fff;

    .match-details__market-header {
        margin-bottom: .1rem;
        background-color: #233840;
        color: #fff;
    }

    .breadcrumbs__tournament:hover {
        color: #FFB400;
    }

    .match-details__markets-error {
        color: #000;
    }

    .match-details__markets {
        background-color: #2d2d2d;
    }

    .status-filter-switcher .status-filter-switcher-track {
        background-color: #3D4F53 !important;
    }

    .fancy-bets-slider-wrp {
        border-bottom: 1px solid #5B7177;
    }

    .match-details__bet {
        box-shadow: inset -1px 0 0 0 #5B7177, inset 0 -2px 1px -1px #5B7177;

        &:not(.match-details__bet--disabled) {
            &:hover {
                background: #FFB400;
                color: #000;

                .match-details__bet-value,
                .match-details__bet-value:after {
                    color: #000;
                }
            }
        }

        &.match-details__bet--active {
            background: #FFB400;
            color: #000;

            .match-details__bet-value,
            .match-details__bet-value:after {
                color: #000;
            }
        }
    }

    .match-details__bet--dn {
        .match-details__bet-value:after {
            color: #d10307;
        }
    }

    .match-details__bet--up {
        .match-details__bet-value:after {
            color: #abf210;
        }
    }

    .match-details__bet-value {
        color: #FFB400;
    }

    .match-details__bets--contains-3 {
        .match-details__bet:nth-child(3n),
        .match-details__bet:last-child {
            box-shadow: inset 0 -2px 1px -1px #5B7177;
        }
    }

    .match-details__bets--contains-2 {
        .match-details__bet:nth-child(2n),
        .match-details__bet:last-child {
            box-shadow: inset 0 -2px 1px -1px #5B7177;
        }
    }

    .match-details__bets {
        background: #3D4F53;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }
}

.live-match-details__landscape {
    .live-page__landscape-scrollbox {
        background: #233840;
    }
}


/*
|--------------------------------------------------------------------------
| == 3.1.2.4. LIVE PAGE (MOBILE)
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 3.1.2.4.1. LIVE PAGE MENU
|--------------------------------------------------------------------------
*/

.livematches {
    .livematches__view-selectors {
        background-color: #3D4F53;
        color: #fff;
    }

    .livematches__view-button--active {
        background-color: #FFB400;
    }

    .livematches__sport-item {
        color: #fff;
        border: 1px solid #233840;
        background: #22333A;
    }

    .livematches__sport-match-count {
        background: #121A1D;
        color: #fff;
    }

    .livematches__category-name {
        border-bottom: 1px solid #1D2A2E;
        background-color: #2F3F45;
        color: #BEBEBE;
    }

    .livematches__match-items {
        background: #1D2A2E;
        border-bottom: 1px solid #233840;
        color: #fff;
    }

    .livematch__info {
        .livematch__info-score {
            color: #FFB400;
        }

        .livematch__info-time,
        .livematch__info-status {
            color: #DADAD9;
        }
    }

    .match__icon--can-cashout,
    .match__icon--cant-cashout {
        color: #DADAD9;
    }

    .match__icon--cant-cashout {
        opacity: .4;
    }

    .ll-burger-icon--opened,
    .ll-burger-icon {
        background: #22333a;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.4.2. LIVE PAGE DETAILS
|--------------------------------------------------------------------------
*/

.livematches {
    .match-details__competitor-without-img {
        .match-details__breadcrumbs-icon {
            color: #fff;
        }
    }

    .match-details__header-info-competitor:after {
        background-color: #000000;
    }

    .match-details__competitor-without-img-icon::before {
        color: #fff;
    }

    .match-details__competitor-name {
        color: #fff;
    }

    .match-details__description {
        color: #fff;
    }

    .live-match-details__market-group {
        background-color: #1C1C1C;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        color: #fff;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }

    .match-details__empty-markets {
        background-color: #2d2d2d;
        color: #fff;
    }

    .matches__bets-btn--dn,
    .match-details__bet--dn {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            color: #d10307;
        }
    }

    .matches__bets-btn--up,
    .match-details__bet--up {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            color: #abf210;
        }
    }

    .live-widgets__nav-item--active {
        color: #FFB400;

        .live-widgets__nav-icon {
            color: #FFB400;
        }
    }

    .match-details__bet--active .match-details__bet-value-odd:after,
    .matches__bets-btn--active .matches__bets-btn-value-odd:after {
        color: #000 !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.5. LIVE CALENDAR
|--------------------------------------------------------------------------
*/

.live-calendar {
    .live-calendar__back-button,
    .live-calendar__title {
        background: #22333A;
    }

    .live-calendar__back-button {
        color: #fff;
    }

    .live-calendar__title {
        color: #FFB400;
    }

    .live-calendar__preloader-wrapper {
        background: #1D272E85;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
}

.prematch--tablet {
    .live-details__header > *:not(:last-child) {
        background-color: #233840;
    }

    .live-details__back-button {
        color: #FFB400;
    }
}

.prematch--mobile {
    .live-calendar__no-data-text {
        border: 1px solid #BEBEBE;
        background: #3D4F53;
        color: #fff;
    }
}