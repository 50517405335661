/*
1. COUPONS
├── 1.1. COUPONS COMMON ELEMENTS
│   ├── 1.1.1 COUPONS MODALS
│   └── 1.1.2 COUPONS LIVE INDICATOR
├── 2. COUPONS
│   ├── 2.1. COUPONS MOBILE/TABLET
│   └── 2.2. COUPONS DESKTOP
└── 3. SPORT PAGE RIGHT PANEL DESKTOP
    └── 3.1 DESKTOP RIGHT PANEL TABS
*/

/*
|--------------------------------------------------------------------------
| == 1.1. COUPONS COMMON ELEMENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 1.1.1 COUPONS MODALS
|--------------------------------------------------------------------------
*/

/* ---------------------- Clear coupons modal ---------------------- */
.coupons-clear-modal {
    @extend %G-coupons-clear-modal;

    background-color: #fff;
}

/* ---------------------- Success coupons modal ---------------------- */
.ll-modal--success-bet-modal {
    @extend %G-success-bet-modal;

    .ll-modal__scrollbox--mobile,
    .ll-modal__scrollbox--tablet,
    .ll-modal__scrollbox--desktop {
        background-color: #3D4F53;
    }

    .success-bet-modal__heading {
        color: #FFB400;
    }

    .success-bet-modal__block {
        border: 1px solid #3d4f53;
        background: #2f3f45;
    }

    .success-bet-modal__value {
        color: #FFB400;
        font-weight: 500;
    }

    .coupons__box:nth-of-type(odd) {
        background: #324A53;
        margin: 0;
    }

    .coupons__box:nth-of-type(even) {
        background: #2f3f45;
        margin: 0;
    }

    .coupons__match-grid--selected {
        font-weight: 500;
    }

    .coupons__box {
        border: 1px solid #22333a;
        font-size: 1.4rem;

        &:not(:last-of-type) {
            border-bottom: none;
        }
    }

    .coupons__match-time {
        color: #BEBEBE;
    }

    .coupons__match-type {
        color: #BEBEBE;
    }

    .ll-coupon__name {
        color: #fff;
    }

    .coupons__event-value {
        color: #FFB400;
    }

    &.ll-modal--mobile {
        @extend %G-success-bet-modal--mobile;
    }

    &.ll-modal--tablet {
        @extend %G-success-bet-modal--tablet;
    }

    &.ll-modal--desktop {
        @extend %G-success-bet-modal--desktop;
    }
}


/*
|--------------------------------------------------------------------------
| == 1.1.2 COUPONS LIVE INDICATOR
|--------------------------------------------------------------------------
*/
.coupons__live-indicator {
    @extend %G-coupons__live-indicator;

    color: #FFB400;
    animation-name: live_coupon_blink;
}

@-moz-keyframes live_coupon_blink {
    0% {
        color: #FFB400;
    }
    50% {
        color: #F1DBA1;
    }
    100% {
        color: #FFB400;
    }
}

@-webkit-keyframes live_coupon_blink {
    0% {
        color: #FFB400;
    }
    50% {
        color: #F1DBA1;
    }
    100% {
        color: #FFB400;
    }
}

@keyframes live_coupon_blink {
    0% {
        color: #FFB400;
    }
    50% {
        color: #F1DBA1;
    }
    100% {
        color: #FFB400;
    }
}

///*
//|--------------------------------------------------------------------------
//| == 1.2. COUPONS DESKTOP
//|--------------------------------------------------------------------------
//*/
//.coupons--desktop {
//    @extend %G-coupons--desktop;
//
//    .coupons__loader {
//        background: rgba(0, 0, 0, 0.1);
//    }
//
//    .coupons__header {
//        background: #22333A;
//        color: #fff;
//    }
//
//    .coupons__header-icon-count {
//        background-color: #F5782C;
//
//        &.animated {
//            background-color: #ed1c24;
//        }
//    }
//
//    .prematch__coupons--empty {
//        background-color: #1D272E;
//        color: #fff;
//    }
//
//    .coupons__box {
//        background: #324A53;
//        color: #BEBEBE;
//    }
//
//    .coupons__box {
//        border: 1px solid #5B7177;
//    }
//
//    .coupons__match-type {
//        color: #BEBEBE;
//    }
//
//    .coupons__remove {
//        color: #BEBEBE;
//
//        &:hover {
//            color: #F5782C;
//        }
//    }
//
//    .coupons__event-value {
//        color: #FFB400;
//    }
//
//    .coupons__match-grid--selected {
//        color: #fff;
//    }
//
//    .coupons__bet-system-picker {
//        background: #3D4F53;
//        border-bottom: 1px solid transparent;
//
//        &.coupons__bet-system-picker--nullable {
//            box-shadow: inset 0 0 0 1px #D90404;
//            background: #F5EAEA;
//            color: #202020;
//        }
//
//        .ll-dropdown-menu__icon,
//        .ll-dropdown-menu__icon--opened {
//            color: #FFB400;
//        }
//    }
//
//    .coupons__bet-system-picker-item {
//        background-color: #2F3F45;
//        color: #fff;
//        box-shadow: inset 0 1px 0 0 hsla(0, 0%, 100%, 0.2),
//        inset 1px 0 0 0 hsla(0, 0%, 100%, 0.2),
//        inset -1px 0 0 0 hsla(0, 0%, 100%, 0.2);
//    }
//
//    .coupons__bet-system-picker-button {
//        color: #fff;
//    }
//
//    .coupons__bet-system-picker-button-text,
//    .ll-dropdown-menu-item--active .coupons__bet-system-picker-item,
//    .coupons__bet-system-picker-item:hover {
//        color: #FFB400;
//    }
//
//    .coupons__bet-system-picker-item {
//        &:not(:last-child) {
//            border-bottom: 1px solid #fff;
//        }
//    }
//
//    .coupons__amount-head {
//        color: #000;
//    }
//
//    .coupons__amount-block {
//        background-color: #1D272E;
//        border: 1px solid #FFB400;
//        color: #fff;
//    }
//
//    .coupons__odds {
//        border: 1px solid #FFB400;
//        background-color: #FFB400;
//    }
//
//    .coupons__amount-input input {
//        background: transparent;
//        color: #fff !important;
//    }
//
//    .coupons__amount-row--total {
//        color: #fff;
//    }
//
//    .coupons__amount-value {
//        color: #FFB400;
//    }
//
//    .coupons__odds .coupons__amount-value {
//        color: #000;
//    }
//
//    .coupons__checkbox {
//        background-color: #3D4F53;
//
//        .dx-checkbox-text {
//            color: #fff;
//        }
//    }
//
//    .coupons__clear {
//        border: 1px solid #FFB400;
//        background: none;
//        color: #FFB400;
//    }
//
//    .coupons__submit-wrp--error {
//        opacity: 0.5;
//    }
//
//    .coupons__submit-wrp:not(.coupons__submit-wrp--error) {
//        .coupons__submit {
//            border: 1px solid #FFB400;
//            background-color: #FFB400;
//            color: #000;
//        }
//        .dx-button.dx-state-disabled {
//            background: #FFB400;
//        }
//    }
//}
//
//.body--with-cursor {
//    .coupons__bet-system-picker:hover {
//        background: #5B7177;
//    }
//
//    .coupons__submit-wrp:not(.coupons__submit-wrp--error):hover {
//        .coupons__submit {
//            background: transparent;
//            color: #FFB400;
//            border-color: #FFB400;
//        }
//    }
//
//    .coupons__clear:hover {
//        background-color: #FFB400;
//        color: #000;
//    }
//}
//
///*
//|--------------------------------------------------------------------------
//| == 1.2.1 COUPONS TABS
//|--------------------------------------------------------------------------
//*/
//.coupon-tabs {
//    @extend %G-coupons-tabs;
//
//    .coupon-tabs__header-tab {
//        background: #1D272E;
//        color: #fff;
//
//        &.active {
//            background: #22333A;
//        }
//    }
//
//    .coupon-tabs__header-icon-count {
//        background-color: #F5782C;
//        color: #fff;
//
//        &.animated {
//            background-color: #FFB400;
//        }
//    }
//}
//
//.page-wrapper--live {
//    .coupon-tabs {
//        @extend %G-coupons-tabs--live;
//    }
//}
//
///*
//|--------------------------------------------------------------------------
//| == 1.3. COUPONS PANEL - MOBILE & TABLET
//|--------------------------------------------------------------------------
//*/
//
//.coupons--swipe,
//.coupons--swipe--one {
//    @extend %G-coupons--tablet--mobile;
//
//    background: #fff;
//
//    .coupons__container {
//        border-top: 0.1rem solid #3D4F53;
//        border-bottom: none;
//        background: #617379;
//    }
//
//    .coupons__bet-value {
//        background: #F5782C;
//        color: #fff;
//    }
//
//    .coupons__bet-btn {
//        background: #FFB400;
//        border: none;
//        color: #000000;
//    }
//
//    .coupons__match-grid {
//        color: #B0B0B0;
//    }
//
//    .coupons__checkbox-label,
//    .coupons__match-grid--selected {
//        color: #fff;
//    }
//
//    .coupons__input-container,
//    .coupons__bet-system-picker {
//        .coupons__bet-system-picker--nullable {
//            border-color: #EB0F0F;
//            background: #F5DFDF;
//        }
//    }
//
//    .coupons__input-container {
//        color: #fff;
//
//        .coupons__input-currency,
//        .dx-widget input {
//            color: #121A1D !important;
//        }
//
//        &.coupons__input-container--inner {
//            border-top: 1px solid #FFB400;
//            border-bottom: 1px solid #FFB400;
//            background: #FFB400;
//            color: #121A1D;
//        }
//
//        &.coupons__input-container--top {
//            border: .2rem solid #FFB400;
//
//            .coupons__input-currency,
//            .dx-widget input {
//                color: #fff !important;
//            }
//        }
//    }
//
//    .coupons__input-wrp {
//        color: #fff;
//    }
//
//    .coupons__close-icon {
//        background: #F5782C;
//        color: #fff;
//    }
//
//    .coupons__bet-counters {
//        color: #fff;
//    }
//
//    .coupons__bet-system-picker {
//        background-color: #3D4F53;
//        border-top: 1px solid #3D4F53;
//        border-bottom: 1px solid #3D4F53;
//        color: #fff;
//
//        &.ll-dropdown-wrapper--opened {
//            border-bottom-left-radius: .3rem;
//            border-bottom-right-radius: .3rem;
//            box-shadow: 3px -2px 5px 0 #00000038;
//            border: none;
//        }
//    }
//
//    .coupons__bet-system-picker-button {
//        background: none;
//        color: #fff;
//    }
//
//    .coupons__bet-system-picker-button-text {
//        color: #fff;
//    }
//
//    .coupons__input-currency {
//        color: #fff;
//    }
//
//    .coupons__bet-system-dropdown-menu {
//        box-shadow: 3px -2px 5px 0 #00000038;
//    }
//
//    .ll-dropdown-menu-item:not(:last-child),
//    .ll-dropdown-menu-item--active:not(:last-child) {
//        .coupons__bet-system-picker-item {
//            border-bottom: 0.1rem solid #3D4F53;
//        }
//    }
//
//    .coupons__bet-system-picker .coupons__bet-system-picker-item,
//    .coupons__bet-system-picker .ll-submenu__item--active {
//        background: #1D272E;
//        color: #fff;
//    }
//
//    .ll-dropdown-menu-item--active .coupons__bet-system-picker-item {
//        color: #FFB400;
//        background-color: #3D4F53;
//    }
//
//    .coupons__inner-odds {
//        background: #1D272E;
//        color: #fff;
//    }
//
//    .coupons__inner-odds-key {
//        color: #FFB400;
//    }
//
//    .coupons__checkbox {
//        background-color: #121A1D;
//
//        .dx-checkbox {
//            .dx-checkbox-icon {
//                border-color: #FFB400;
//            }
//
//            &.dx-checkbox-checked {
//                .dx-checkbox-icon {
//                    background-color: #FFB400 !important;
//                }
//            }
//
//            .dx-checkbox-text {
//                color: #fff;
//            }
//        }
//
//        .dx-checkbox-text {
//            color: #fff;
//        }
//    }
//
//    .coupons__inner-btn {
//        background-color: #121A1D;
//    }
//
//    .coupons__detete-btn {
//        background: transparent;
//        border: 0.2rem solid #BEBEBE;
//        color: #BEBEBE;
//    }
//}
//
//.success-bet-modal {
//    .coupons__box:nth-of-type(odd) {
//        background: #324A53;
//        margin: 0;
//    }
//
//    .coupons__box:nth-of-type(even) {
//        background: #2f3f45;
//        margin: 0;
//    }
//
//    .coupons__match-grid--selected {
//        font-weight: 500;
//    }
//
//    .coupons__box {
//        font-size: 1.4rem;
//    }
//
//    .coupons__box-scroll-container {
//        border: 1px solid #22333a;
//    }
//}
//.success-bet-modal,
//.coupons--swipe,
//.coupons--swipe--one {
//    .coupons__match-time {
//        color: #BEBEBE;
//    }
//
//    .coupons__scrollbox {
//        &.coupons__scrollbox--closed {
//            max-height: 0;
//
//            .coupons__bet-btn {
//                position: absolute;
//                left: 50%;
//                transform: translate(-50%);
//                padding: 1.1rem 4rem;
//                border-radius: 0.5rem;
//                text-transform: uppercase;
//            }
//        }
//    }
//
//    .coupons__box--list:nth-of-type(odd) {
//        background: #324A53;
//        margin: 0;
//
//    }
//
//    .coupons__box--list:nth-of-type(even) {
//        background: #3D4F53;
//        margin: 0;
//    }
//
//    .coupons__remove {
//        background: none;
//        border: .1rem solid #BEBEBE;
//    }
//
//    .coupons__remove-icon {
//        color: #BEBEBE;
//    }
//
//    .coupons__match-type {
//        color: #BEBEBE;
//    }
//
//    .ll-coupon__name {
//        color: #fff;
//    }
//
//    .coupons__event-value {
//        color: #FFB400;
//    }
//}
//
//.coupons--swipe {
//    @extend %G-coupons--swipe;
//}
//
//.ll-content-container--landscape .coupons--tablet.coupons--pre {
//    &.coupons--swipe,
//    &.coupons--swipe--one {
//        width: calc(100% - 1rem - #{$size-width__prematch-menu--tablet-1024});
//        right: 0;
//        left: auto;
//
//        @media screen and (max-width: 1023px) {
//            width: calc(100%);
//        }
//    }
//}
//
//.coupons--swipe.coupons--tablet,
//.coupons--swipe--one.coupons--tablet {
//    @extend %G-coupons--mobile;
//
//    bottom: $size-height--footer_tablet;
//
//    .coupons__scrollbox.coupons__scrollbox--open {
//        max-height: $size-max-height--coupons-list_tablet;
//    }
//}
//
//.coupons--swipe.coupons--mobile,
//.coupons--swipe--one.coupons--mobile {
//    @extend %G-coupons--mobile;
//    color: #fff;
//
//    bottom: $size-height--footer_mobile;
//
//    .coupons__container-top,
//    .coupons__container-top--whide {
//        box-shadow: 0 0.8rem 0.6rem -0.45rem rgba(0, 0, 0, 0.06),
//        0 0.8rem 0.6rem -0.45rem rgba(0, 0, 0, 0.13);
//    }
//
//    .coupons__scrollbox.coupons__scrollbox--open.coupons__scrollbox--landscape {
//        max-height: $size-max-height--coupons-list_mobile-landscape;
//    }
//
//    .coupons__scrollbox.coupons__scrollbox--open.coupons__scrollbox--portrait {
//        max-height: $size-max-height--coupons-list_mobile-portrait;
//    }
//}
//
//.coupons__match-odd-info__with-dots {
//    &:before {
//        box-shadow: 0 0 0 2px #324A53;
//        background-color: #BEBEBE;
//    }
//    &:after {
//        border-left: 1px solid #BEBEBE;
//    }
//
//    .coupons__match-type-delete {
//        color: #BEBEBE;
//    }
//}
//
//.coupons__custom-warning {
//    color: #FFB400;
//}
//
//.body--with-cursor {
//    .coupons__match-odd-info__with-dots {
//        .coupons__match-type-delete:hover {
//            color: #FFB400;
//        }
//    }
//}

/*
|--------------------------------------------------------------------------
| == 2. COUPONS
|--------------------------------------------------------------------------
*/

.coupons {
    @extend %G-coupons;

    .coupons__amount {
        color: #FFFFFF;

        .coupons__amount-wrapper {
            border-color: #BEBEBE;

            &.amount-wrapper--error {
                border-color: #D75A65;
            }
        }

        .coupons__amount-input input,
        .dx-texteditor.dx-editor-filled.dx-state-disabled input {
            color: #FFFFFF;
            background: transparent;
        }

        .dx-texteditor.dx-editor-filled.dx-state-disabled {
            opacity: 1;
        }

        .coupons__amount-input--dummy {
            background-color: #1D272E;
        }
    }

    .coupons-list__event--odd .coupons__amount-label--multi {
        background-color: #324A53;
    }

    .coupons-list__event--even .coupons__amount-label--multi {
        background: #3D4F53;
    }

    .coupons__settings {
        .coupons__amount-limit {
            color: #BEBEBE;
        }

        .amount-limit--error {
            color: #D75A65;
        }

        .coupons__bet-system-picker--nullable {
            border: 1px solid #D75A65;
        }

        .coupons__amount-wrapper {
            border-color: #324A53;
        }

        .coupons__amount-preset-btn {
            background: #F5782C;
            color: #000000;
        }

        .coupons__amount-preset-edit {
            color: #F5782C;
            border-color: #F5782C;

            &:has(.doruk-close) {
                background-color: #22333A;
            }

            .doruk-close {
                color: #BEBEBE;
            }
        }

        .amount-preset-editor__item {
            border: 1px solid #324A53;
        }

        .amount-preset-editor__btn {
            background-color: #F5782C;
            color: #000000;
        }

        .coupons__bet-system-picker-button-text {
            background: transparent;
            color: #FFFFFF !important;
        }

        .coupons__bet-system-picker {
            background: #22333A;

            &:hover,
            &.ll-dropdown-wrapper--opened {
                background: #324A53;
            }

            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__icon--opened {
                color: #FFFFFF;
            }

            .ll-dropdown-menu-item:not(:last-child),
            .ll-dropdown-menu-item--active:not(:last-child) {
                .coupons__bet-system-picker-item {
                    border-bottom: 1px solid #5B7177;
                }
            }
        }

        .coupons__bet-system-picker-button {
            color: #fff;
        }

        .coupons__bet-system-picker-item {
            background-color: #22333A;

            .dx-checkbox-container {
                color: #FFFFFF;
            }
        }

        .coupons__bet-system-picker-button-text,
        .ll-dropdown-menu-item--active .coupons__bet-system-picker-item,
        .coupons__bet-system-picker-item:hover {
            .dx-checkbox-container {
                color: #FFB400;
            }
        }

        .ll-dropdown-menu-item--active .coupons__bet-system-picker-item {
            .dx-checkbox-icon {
                background: #FFB400;
            }
        }

        .coupons__bet-system-picker-item:hover {
            .dx-checkbox-icon {
                border-color: #FFB400;
            }
        }

        .coupons__accept-btn {
            background-color: #FFB400;
            color: #000000;
        }

        .coupons__detete-btn {
            color: #FFB400;
            border-color: #FFB400;
        }

        .coupons__checkbox {
            .dx-checkbox-text {
                color: #FFFFFF;
            }
        }

        .coupons__total-row--total {
            color: #F5782C;
        }

        .coupons__amount-row--total {
            color: #fff;
        }
    }

    .coupons__custom-warning {
        color: #BEBEBE;
    }
}

.coupons-list {
    @extend %G-coupons-item;

    .coupons-list__event {
        border: 1px solid #324A53;
    }

    .coupons-list__event--odd {
        background: #324A53;
    }

    .coupons-list__event--even {
        background: #3D4F53;
    }

    .coupons-list__event-background-icon {
        color: #5B7177;
    }

    .coupons-list__event-date,
    .event-info__match-name,
    .coupons-list__event-type {
        color: #BEBEBE;
    }

    .coupons-list__event-remove-btn {
        background: #5B7177;
        color: #BEBEBE;

        &:hover {
            color: #FFFFFF;
        }
    }

    .only-single-error__icon {
        color: #FFB400;
    }

    .only-single-error__icon-container {
        background-color: #F5782C;

        .only-single-error__icon {
            color: #fff;
        }
    }

    .only-single-error {
        background: #F5782C80; //for browsers without linear-gradient
        background: linear-gradient(#F5782C4D, #F5782C4D), linear-gradient(#1D272Ecc, #1D272Ecc);
        color: #fff;
    }

    .coupons-list__event-part--main {
        color: #FFFFFF;
    }

    .coupons__event-value {
        color: #FFB400;
    }

    .event-info__custom-bet--with-dots {
        &:before {
            box-shadow: 0 0 0 2px #324A53;
            background-color: #BEBEBE;
        }

        &:after {
            border-left: 1px solid #BEBEBE;
        }
    }
    .event-info__custom-bet-selection-delete {
        color: #BEBEBE;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.1. COUPONS MOBILE/TABLET
|--------------------------------------------------------------------------
*/

.coupons--mobile,
.coupons--tablet {
    @extend %G-coupons--mobile--tablet;

    background: #5B7177;

    .coupons__container {
        border-top: 0.1rem solid #324A53;
        border-bottom: none;
        background: #5B7177;
        color: #FFFFFF;
    }

    .coupons__bet-value {
        background: #FFB400;
        color: #000000;
    }

    .coupons__close-icon {
        color: #FFFFFF;
    }

    .coupons__view-tabs {
        background: #22333A;
        color: #FFB400;
    }

    .coupons__view-tab {
        &:not(:last-of-type) {
            &:after {
                background-color: #324A53;
            }
        }

        &.active {
            background: #FFB400;
            color: #000000;
        }

        &.disabled {
            opacity: .7;
        }
    }

    .coupons-list__event {
        margin-bottom: 0;
        border: none;
        border-bottom: 1px solid #495E64;
    }

    .coupons__settings {
        background: #1D272E;
    }

    .coupons__amount-wrapper {
        border: 1px solid #324A53;
    }

    .coupons__bet-system-dropdown-menu {
        border: 1px solid #324A53;
    }
}

.coupons--mobile {
    bottom: $size-height--footer_mobile;
}

.coupons--tablet {
    bottom: $size-height--footer_tablet;

    .coupons__scrollbox.coupons__scrollbox--open {
        max-height: $size-max-height--coupons-list_tablet;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. COUPONS DESKTOP
|--------------------------------------------------------------------------
*/

.coupons--desktop {
    @extend %G-coupons--desktop;

    .coupons__loader {
        background: rgba(0, 0, 0, 0.1);
    }

    .prematch__coupons--empty {
        background: #22333A;
        color: #fff;
        border-top: 1px solid #324A53;
    }

    .coupons__view-tabs {
        background: #22333A;
        color: #BEBEBE;
        border-top: 1px solid #324A53;
    }

    .coupons__view-tab {
        &:not(:last-of-type) {
            &:after {
                background-color: #324A5390;
            }
        }

        &.active {
            color: #fff;
        }
    }

    .coupons__settings {
        background: #1D272E;
    }

    .coupons__amount-wrapper {
        border: 1px solid #324A53;
    }
}

.body--with-cursor {
    .event-info__custom-bet-selection-delete:hover {
        color: #fff;
    }

    .coupons__view-tab:not(.disabled):not(.active):hover {
        color: #fff;
    }

    .coupons__accept-btn:hover {
        background: #F5782C;
        opacity: 1;
    }

    .coupons__detete-btn:hover {
        background: #FFB400;
        color: #1D272E;
    }

    .coupons__amount-wrapper:hover {
        border-color: #5B7177;
    }

    .coupons__amount--multi .coupons__amount-wrapper:hover {
        border-color: #fff;
    }

    .coupons__amount-preset-btn:hover {
        background: #FFB400;
    }

    .coupons__amount-preset-edit:hover {
        border: 1px solid #FFB400;
        background-color: #1D272E;
        color: #FFB400;

        &:has(.doruk-close) {
            border-color: #22333A;
            background-color: #22333A;
        }

        .doruk-close {
            color: #fff;
        }
    }

    .amount-preset-editor__btn:hover {
        background-color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 3. SPORT PAGE RIGHT PANEL DESKTOP
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.1 DESKTOP RIGHT PANEL TABS
|--------------------------------------------------------------------------
*/

.coupon-tabs {
    @extend %G-coupons-tabs;

    .coupon-tabs__header-tab {
        background: #324A53;
        color: #fff;

        &.active {
            background: #22333A;
        }
    }

    .coupon-tabs__header-icon-count {
        background-color: #FFB400;
        color: #000;

        &.animated {
            background-color: #FFB400;
        }
    }
}

.page-wrapper--live {
    .coupon-tabs {
        @extend %G-coupons-tabs--live;
    }
}