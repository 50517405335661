/*
|--------------------------------------------------------------------------
| CSS DIRECTORY (tree builder https://tree.nathanfriend.io/)
|--------------------------------------------------------------------------
├── 1.GLOBAL
│   ├── 1.1. SIZES
│   ├── 1.2. COLORS
│   └── 1.3.test
├── 2.HEADER
│   ├── 2.1.test
│   ├── 2.2.test
│   └── 2.3.test
├── 3.FOOTER
│   ├── 3.1.test
│   ├── 3.2.test
│   └── 3.3.test
└── 4.PAGES
    ├── 4.1.PREMATCH PAGE
    ├── 4.2.LIVEMATCH PAGE
    └── 4.3. SLOTS-CASINO PAGES
*/

/*
|--------------------------------------------------------------------------
| == 1.GLOBAL
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 1.1. SIZES
|--------------------------------------------------------------------------
*/
$size-height--footer_mobile: 50px;
$size-height--footer_tablet: 50px;
$size-height--footer_desktop: 45px;

$size-height--header_mobile: 6rem;
$size-height--header_tablet: 6rem;
$size-height--header_desktop: 90px;

$size-height--header-nav-menu_mobile: 5rem;
$size-height--header-nav-menu_tablet: 5rem;
$size-height--header-menu_desktop: 50px;
$size-height--content-footer: 242px;

// layout
$size-max-height--page-container_desktop: calc(100vh - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop});
$size-max-height--page-container_tablet: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet} - #{$size-height--header-nav-menu_tablet});
$size-min-height--page-container_tablet: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet} - #{$size-height--header-nav-menu_tablet});
$size-max-height--page-container_mobile: calc(100vh - #{$size-height--footer_mobile} - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile});
$size-height__page-content-100vh_mobile: calc((var(--vh, 1vh) * 100) - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - #{$size-height--footer_mobile});

// coupons
$size-max-height--coupons-list_tablet: calc(#{$size-max-height--page-container_tablet} - 15rem);
$size-max-height--coupons-list_mobile-landscape: calc(#{$size-max-height--page-container_mobile} - 10rem);
$size-max-height--coupons-list_mobile-portrait: calc(#{$size-max-height--page-container_mobile} - 20rem);

// slider
$size-width--slider-arrow_desktop: 40;
$size-width--slider-arrow_tablet: 30;
$size-width--slider-arrow_mobile: 30;

// use without 'px'
$size-height--header-upper-part_desktop: 90;
$size-height--prematch-menu-item: 40;
$size-height--prematch-one-coupon: 65;
$size-height--prematch-few-coupons: 40;

$size-height--casino-game-featured_desktop: 295;
$size-width--casino-game-featured_desktop: 220;
$size-margin--casino-game-featured_desktop: 10;
$size-height--casino-game_desktop: 295;
$size-width--casino-game_desktop: 220;
$size-margin--casino-game_desktop: 10;

$size-height--slots-game-featured_desktop: 240;
$size-width--slots-game-featured_desktop: 240;
$size-margin--slots-game-featured_desktop: 10;
$size-height--slots-game_desktop: 125;
$size-width--slots-game_desktop: 190;
$size-margin--slots-game_desktop: 10;

$size-height--casino-game-featured_tablet: 295;
$size-width--casino-game-featured_tablet: 220;
$size-margin--casino-game-featured_tablet: 10;
$size-height--casino-game_tablet: 295;
$size-width--casino-game_tablet: 220;
$size-margin--casino-game_tablet: 10;

$size-height--slots-game-featured_tablet: 180;
$size-width--slots-game-featured_tablet: 200;
$size-margin--slots-game-featured_tablet: 20;
$size-height--slots-game_tablet: 130;
$size-width--slots-game_tablet: 167;
$size-margin--slots-game_tablet: 15;

$size-height--casino-game-featured_mobile: 125;
$size-width--casino-game-featured_mobile: 90;
$size-margin--casino-game-featured_mobile: 5;
$size-height--casino-game_mobile: 125;
$size-width--casino-game_mobile: 90;
$size-margin--casino-game_mobile: 6;

$size-height--slots-game-featured_mobile: 130;
$size-width--slots-game-featured_mobile: 130;
$size-margin--slots-game-featured_mobile: 4;
$size-height--slots-game_mobile: 105;
$size-width--slots-game_mobile: 90;
$size-margin--slots-game_mobile: 6;

$size-width--slot-battles-game_desktop: 144;
$size-margin--slot-battles-game_desktop: 15;
$size-width--slot-battles-game_mobile: 100;
$size-margin--slot-battles-game_mobile: 10;
$size-width--slot-battles-game_mobile-iphone5: 120;
$size-margin--slot-battles-game_mobile-iphone5: 5;

$size-height--new-thumbnail-game_desktop: 232;
$size-width--new-thumbnail-game_desktop: 176;
$size-margin--new-thumbnail-game_desktop: 7.5;
$size-height--new-thumbnail-game_tablet: 232;
$size-width--new-thumbnail-game_tablet: 176;
$size-margin--new-thumbnail-game_tablet: 7.5;
$size-height--new-thumbnail-game_mobile: 147.5;
$size-width--new-thumbnail-game_mobile: 112;
$size-margin--new-thumbnail-game_mobile: 5;

$size-height--cabinet-google-auth-img: 200;
$size-width--cabinet-google-auth-img: 200;

:export {
    css_sizeHeight_contentHeader_mobile : $size-height--header_mobile;
    css_sizeHeight_contentHeader_tablet : $size-height--header_tablet;
    css_sizeHeight_contentHeaderMenu_mobile : $size-height--header-nav-menu_mobile;
    css_sizeHeight_contentHeaderMenu_tablet : $size-height--header-nav-menu_tablet;
    css_sizeHeight_headerMenu_desktop: $size-height--header-menu_desktop;

    css_sizeHeight_contentFooter : $size-height--content-footer;
    css_sizeHeight_footer_mobile : $size-height--footer_mobile;
    css_sizeHeight_footer_tablet : $size-height--footer_tablet;
    css_sizeHeight_footer_desktop : $size-height--footer_desktop;

    css_couponsList_maxHeight_tablet : $size-max-height--coupons-list_tablet;
    css_couponsList_maxHeight_mobile : $size-max-height--coupons-list_mobile-portrait;

    //coupons
    css_sizeHeight_header_upper_part_desktop : $size-height--header-upper-part_desktop;
    css_sizeHeight_prematchMenuItem : $size-height--prematch-menu-item;
    css_sizeHeight_prematchOneCoupon : $size-height--prematch-one-coupon;
    css_sizeHeight_prematchFewCoupons : $size-height--prematch-few-coupons;

    // slider
    css_sizeWidth_slider-arrow_desktop: $size-width--slider-arrow_desktop;
    css_sizeWidth_slider-arrow_tablet: $size-width--slider-arrow_tablet;
    css_sizeWidth_slider-arrow_mobile: $size-width--slider-arrow_mobile;

    // slots-casino
    css_sizeWidth_casinoGameFeatured_desktop: $size-width--casino-game-featured_desktop;
    css_sizeMargin_casinoGameFeatured_desktop: $size-margin--casino-game-featured_desktop;
    css_sizeWidth_casinoGame_desktop: $size-width--casino-game_desktop;
    css_sizeMargin_casinoGame_desktop: $size-margin--casino-game_desktop;

    css_sizeWidth_slotsGameFeatured_desktop: $size-width--slots-game-featured_desktop;
    css_sizeMargin_slotsGameFeatured_desktop: $size-margin--slots-game-featured_desktop;
    css_sizeWidth_slotsGame_desktop: $size-width--slots-game_desktop;
    css_sizeMargin_slotsGame_desktop: $size-margin--slots-game_desktop;

    css_sizeWidth_casinoGameFeatured_tablet: $size-width--casino-game-featured_tablet;
    css_sizeMargin_casinoGameFeatured_tablet: $size-margin--casino-game-featured_tablet;
    css_sizeWidth_casinoGame_tablet: $size-width--casino-game_tablet;
    css_sizeMargin_casinoGame_tablet: $size-margin--casino-game_tablet;

    css_sizeWidth_slotsGameFeatured_tablet: $size-width--slots-game-featured_tablet;
    css_sizeMargin_slotsGameFeatured_tablet: $size-margin--slots-game-featured_tablet;
    css_sizeWidth_slotsGame_tablet: $size-width--slots-game_tablet;
    css_sizeMargin_slotsGame_tablet: $size-margin--slots-game_tablet;

    css_sizeWidth_casinoGameFeatured_mobile: $size-width--casino-game-featured_mobile;
    css_sizeMargin_casinoGameFeatured_mobile: $size-margin--casino-game-featured_mobile;
    css_sizeWidth_casinoGame_mobile: $size-width--casino-game_mobile;
    css_sizeMargin_casinoGame_mobile: $size-margin--casino-game_mobile;

    css_sizeWidth_slotsGameFeatured_mobile: $size-width--slots-game-featured_mobile;
    css_sizeMargin_slotsGameFeatured_mobile: $size-margin--slots-game-featured_mobile;
    css_sizeWidth_slotsGame_mobile: $size-width--slots-game_mobile;
    css_sizeMargin_slotsGame_mobile: $size-margin--slots-game_mobile;

    // new thumbnail

    css_sizeWidth_newThumbnail_desktop: $size-width--new-thumbnail-game_desktop;
    css_sizeMargin_newThumbnail_desktop: $size-margin--new-thumbnail-game_desktop;
    css_sizeWidth_newThumbnail_tablet: $size-width--new-thumbnail-game_tablet;
    css_sizeMargin_newThumbnail_tablet: $size-margin--new-thumbnail-game_tablet;
    css_sizeWidth_newThumbnail_mobile: $size-width--new-thumbnail-game_mobile;
    css_sizeMargin_newThumbnail_mobile: $size-margin--new-thumbnail-game_mobile;

    // slot battles
    css_sizeWidth_slotsBattlesGame_desktop: $size-width--slot-battles-game_desktop;
    css_sizeMargin_slotsBattlesGame_desktop: $size-margin--slot-battles-game_desktop;
    css_sizeWidth_slotsBattlesGame_mobile: $size-width--slot-battles-game_mobile;
    css_sizeMargin_slotsBattlesGame_mobile: $size-margin--slot-battles-game_mobile;
    css_sizeBottomIndent_slotsBattlesPopup_desktop: $size-height--footer_desktop + 20;
    css_sizeBottomIndent_slotsBattlesPopup_tablet: $size-height--footer_tablet + 20;
    css_sizeBottomIndent_slotsBattlesPopup_mobile: $size-height--footer_mobile + 40;

    // cabinet
    css_sizeHeight_cabinetGoogleAuthImg: $size-height--cabinet-google-auth-img;
    css_sizeWidth_cabinetGoogleAuthImg: $size-width--cabinet-google-auth-img;
}

/*
|--------------------------------------------------------------------------
| == 1.2. COLORS
|--------------------------------------------------------------------------
*/

%main-gradient {
    background: linear-gradient(0deg, #3d4f35, #637858), #3d4f35;
}

%error-bg-gradient {
    background: linear-gradient(0deg, #ff8e51 0%,  #ff5a00 35%, #e28857 100%), #ff8e51;
}

/*
|--------------------------------------------------------------------------
| == 4.1.PREMATCH PAGE
|--------------------------------------------------------------------------
*/
$size-width__prematch-menu--tablet-768: 25rem;
$size-width__prematch-menu--tablet-1024: 30rem;
$size-width__prematch-menu--desktop-1366: 35rem;

$size-width__prematch-list-odds-count--tablet: 4rem;

$size-height__prematch-coupons--one: 6.3rem;
$size-height__prematch-coupons--few: 4rem;

/*
|--------------------------------------------------------------------------
| == 4.2.LIVEMATCH PAGE
|--------------------------------------------------------------------------
*/
$size-width__livematch-menu--tablet-768: 40rem;
$size-width__livematch-menu--tablet-1024: 45rem;
$size-width__livematch-menu--desktop-1360: 50rem;
$size-width__livematch-menu--desktop-1500: 55rem;
$size-width__livematch-menu--desktop-1700: 65rem;

/*
|--------------------------------------------------------------------------
| == 4.3. SLOTS-CASINO PAGES
|--------------------------------------------------------------------------
*/

$slots-casino__header-menu-container--bg: #121A1D;
$slots-casino__header-menu-wrapper--color: #fff;
$slots-casino__header-desktop-menu-wrapper--height: 45px;
$slots-casino__header-tablet-menu-wrapper--height: 50px;
$slots-casino__header-menu-item-active--color: #FFB400;
$slots-casino__header-menu-sub-items--bg: #1D2A2E;
$slots-casino__header-menu-sub-items--border: 1px solid #FFB400;
$slots-casino__header-menu-sub-items--box-shadow: inset 0 0 0 1px #FFB400;
$slots-casino__search--border: 1px solid #ffffff50;
$slots-casino__search--border-radius: 50px;
$slots-casino__search-icon--font-size: 1.6rem;
$slots-casino__search-input--color: #fff;
$slots-casino__header-menu-desktop-icon--font-size: 2rem;
$slots-casino__header-menu-mobile-icon--font-size: 1.6rem;
$slots-casino__game-item-logo-mobile--max-width: 80%;
$slots-casino__game-item-provider--color: #FFB400;
$slots-casino__game-item-name--color: #fff;
$slots-casino__breadcrumbs-searched--color: #FFB400;
$slots-casino__breadcrumbs-searched--font-weight: 600;

$slots__item-provider-container--height: 32px;
