/*
2. LAYOUT
├── 2.1. COMMON LAYOUT COMPONENTS
│   ├── 2.1.1 SOCIAL ICONS
│   └── 2.1.2 LICENSE ICONS
├── 2.2. FOOTER
│   ├── 2.2.1 FOOTER DESKTOP
│   └── 2.2.2 FOOTER MOBILE
├── 2.3. CONTENT
│   ├── 2.3.1 CONTENT FOOTER
├── 2.4. HEADER
│   ├── 2.4.1 HEADER DESKTOP
│   ├── 2.4.2 HEADER MOBILE
│   ├── 2.4.3 HEADER MENU
│   └── 2.4.4 HEADER LANGUAGE PICKER
├── 2.5. MODAL
│   ├── 2.5.1 MODAL DESKTOP
│   └── 2.5.2 MODAL MOBILE
├── 2.6. DRAWER
│   ├── 2.6.1 GAME MENU DRAWER
│   ├── 2.6.2 SUPPORT MENU DRAWER
│   ├── 2.6.3 MY ACCOUNT MENU DRAWER
│   └── 2.6.4 LOGIN DRAWER
├── 2.7. POPOVER
└── 2.8. BACKDROP
*/

/*
|--------------------------------------------------------------------------
| == 2.LAYOUT
|--------------------------------------------------------------------------
*/

.ll-page {
    @extend %G-ll-page;
}

.page-wrapper--desktop {
    @extend %G-page-wrapper--desktop;
}

.page-wrapper__children {
    @extend %G-page-wrapper__children;
}

.ll-content-container-scroll {
    @extend %G-ll-content-container-scroll;
}

.ll-full-width-content-wrapper {
    @extend %G-ll-full-width-content-wrapper;
}

/*
|--------------------------------------------------------------------------
| == 2.1. COMMON LAYOUT COMPONENTS
| == 2.1.1 SOCIAL ICONS
|--------------------------------------------------------------------------
*/

.ll-social-icons--desktop,
.ll-social-icons--tablet,
.ll-social-icons--mobile {
    @extend %G-ll-social-icons;
}

/*
|--------------------------------------------------------------------------
| == 2.1.2 LICENSE ICONS
|--------------------------------------------------------------------------
*/
.ll-license-icons {
    @extend %G-ll-license-icons;

    .ll-license-icons--circle-icon {
        height: 4rem;
        background: url('../../../../static/themes/theme/images/footer/footer_tiny_circle.png') no-repeat center center;
    }

    .ll-license-icons__icon,
    .ll-license-icons__icon--desktop {
        background-color: initial;
        background-size: contain;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. FOOTER
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 2.2.1 FOOTER DESKTOP
|--------------------------------------------------------------------------
*/

.ll-footer--desktop {
    @extend %G-ll-footer--desktop;

    min-height: $size-height--footer_desktop;
    max-height: $size-height--footer_desktop;
    background: #1D2A2E;

    .ll-footer__icon {
        color: #FFB400;
    }

    .ll-footer__link {
        color: #FFB400;
    }

    .ll-footer__btn {
        color: #FFB400;
        background-color: #32484F;

        &.ll-footer__link--active::before {
            border-right: 7px solid transparent;
            border-top: 7px solid #1D2B2F;
            border-left: 7px solid transparent;
        }
    }

    .ll-footer__btn--accent {
        background: #fff;
        color: #000;

        .ll-footer__icon,
        .ll-footer__link {
            color: #000;
        }
    }

    .ll-footer__logo {
        background-image: url('../../../../static/themes/theme/images/logos/logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        @include size(15rem, 3rem)
    }
}

.body--with-cursor {
    .ll-footer--desktop {
        .ll-footer__btn:hover {
            background: #FFB400;

            .ll-footer__link,
            .ll-footer__icon {
                color: #1D2A2E;
            }
            .footer-featured__background-img-blur {
                background: #FFB400;
            }
        }
    }

    .footer-featured__background-img-blur {
        background: radial-gradient(#32484f60 0%, #32484f 70%);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2.2 FOOTER MOBILE
|--------------------------------------------------------------------------
*/

.ll-footer--mobile {
    @extend %G-ll-footer--mobile;

    min-height: $size-height--footer_mobile;
    max-height: $size-height--footer_mobile;
    border-top: 1px solid #212633;
    background-color: #121A1D;

    .slider__arrow {
        color: #fff;
    }

    .ll-footer__item {
        color: #fff;

        &.ll-footer__item--featured.ll-footer__item--active {
            color: #FFB400;
        }
    }

    .ll-footer__money-item-part:first-of-type:after {
        background: #5B7177;
    }

    .footer-featured__background-img-blur {
        background: radial-gradient(#121A1D85 0%, #121A1D 70%);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.3 CONTENT
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 2.3.1 CONTENT FOOTER
|--------------------------------------------------------------------------
*/
.ll-content__footer {
    @extend %G-ll-content__footer;
    height: $size-height--content-footer;

    background: #060D17;
    color: #fff;

    .footer-menu__section-title,
    .footer-menu__item {
        color: #d6d6d6;
    }

    .footer-license__link {
        color: #d6d6d6;
    }

    .footer-license__icon--desktop {
        color: #FFB400;
    }
}

.body--with-cursor {
    .ll-content__footer {
        .footer-menu__item:hover,
        .footer-menu__support-mail-link:hover,
        .footer-license__link:hover {
            color: #F5782C;

            .footer-license__icon--desktop {
                color: #F5782C;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4. HEADER
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 2.4.1 HEADER DESKTOP
|--------------------------------------------------------------------------
*/
.ll-header--desktop {
    @extend %G-ll-header--desktop;

    background-color: #1D2A2E;

    .ll-header__top {
        height: $size-height--header_desktop;
    }

    .ll-header-btn {
        background: #32484F;
        color: #FFB400;

        &.ll-header-btn--secondary {
            background: #5B7177;
            color: #fff;
        }

        &.ll-header-btn--accent {
            background: #FFB400;
            color: #1D2A2E;
        }
    }

    .ll-header__logo {
        background: url('../../../../static/themes/theme/images/logos/logo.png') no-repeat;
        background-size: contain;
    }

    .ll-header__button {
        color: #FFB400;
        @include size(2.5rem);
        margin-right: 1.5rem;

        .ll-header__button-icon {
            font-size: 2.5rem;
        }
    }

    .ll-header__messages-counter {
        background: #FFB400;
        color: #000;
    }

    .ll-header__sign-out-btn {
        border-color: #FFB400;
        color: #FFB400;
        background: transparent;
    }
}

.body--with-cursor {
    .ll-header--desktop {
        .ll-header-btn {
            &:hover {
                background: #FFB400;
                color: #1D2A2E;
            }

            &.ll-header-btn--secondary {
                &:hover {
                    background: transparent;
                    box-shadow: inset 0 0 1px 1px #5F5F5F;
                    color: #fff;
                }
            }

            &.ll-header-btn--accent {
                &:hover {
                    background: transparent;
                    box-shadow: inset 0 0 1px 1px #FFB400;
                    color: #FFB400;
                }
            }
        }

        .ll-header__sign-out-btn {
            &:hover {
                background: #FFB400;
                color: #1D2A2E;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.2 HEADER MOBILE
|--------------------------------------------------------------------------
*/

.ll-header--mobile {
    @extend %G-ll-header--mobile;
    background: #1D2A2E;

    .ll-header__menu-button {
        color: #fff;
    }

    .ll-header__button {
        margin-right: 1.5rem;
    }

    .ll-header__button-icon {
        color: #FFB400;
    }

    .ll-header__logo {
        @include size(135px, 50px);
        background: url('../../../../static/themes/theme/images/logos/logo.png') no-repeat;
        background-size: contain;
    }

    .active-bets__icon-count {
        background-color: #F5782C;
        color: #fff;
        border-color: #1d2a2e;

        &.animated {
            background-color: #FFB400;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.3 HEADER MENU
|--------------------------------------------------------------------------
*/

.ll-header__menu--sticky,
.slick-list__top-container--desktop,
.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    @extend %G-ll-header__menu;

    height: $size-height--header-menu_desktop;
}

.slick-list__top-container--desktop,
.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    // .ll-dropdown-menu {
    //     color: #fff;
    // }

    .slick-arrow {
        @include mq('tablet') {
            background: #121A1D;
        }
    }

    .menu-item--desktop {
        &:not(:first-child):before {
            background-color: #121A1D;
        }

        .menu-item__is-new-indicator {
            background: #fff;

            &:after {
                border-left: .6rem solid #121a1d;
            }
        }

        .menu-item__is-new-indicator-label {
            color: #121a1d;
        }

        &:hover,
        &.active {
            color: #FFB400;

            .menu-item__is-new-indicator {
                background: #FFB400;
            }
        }
    }

    .menu-item--mobile,
    .menu-item--tablet {
        .menu-item__is-new-indicator {
            background: #FFB400;

            &:after {
                border-left: .6rem solid #121a1d;
            }
        }

        .menu-item__is-new-indicator-label {
            color: #121a1d;
        }

        &.active {
            box-shadow: none;
            background-color: #FFB400;

            .ll-header__item-text,
            .menu-item__icon,
            .menu-item__label,
            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__text {
                color: #121A1D;
            }
            .menu-item__is-new-indicator {
                background: #121a1d;

                &:after {
                    border-left: .6rem solid #FFB400;
                }
            }
            .menu-item__is-new-indicator-label {
                color: #FFB400;
            }
        }
    }
}

.slick-list__top-container--desktop {
    @extend %G-slick-list__top-container--desktop;
    background: #121A1D;
}

.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    @extend %G-slick-list__top-container--mobile;

    .menu-item--desktop.active {
        box-shadow: none;

        .ll-header__item-text,
        .menu-item__icon,
        .menu-item__label,
        .ll-dropdown-menu__icon,
        .ll-dropdown-menu__text {
            color: #FFB400;
        }
    }
}

.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    background: #121A1D;
}

.ll-header__menu--sticky {
    @extend %G-ll-header__menu--sticky;
}

.header-menu-subs {
    @extend %G-header-menu-subs;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1254901961);

    &.menu-sub-item--mobile,
    &.menu-sub-item--tablet {
        background-color: #1D2B2F;
    }

    &.menu-sub-item--desktop {
        @extend %G-header-menu-subs--desktop;

        background-color: #1D2B2F;
    }
}

.header-menu-sub-item {
    @extend %G-header-menu-sub-item;

    color: #fff;

    &:not(:last-child) {
        border-bottom: 1px solid #2e3b40;
    }
}

.body--with-cursor {
    .slick-list__top-container--desktop,
    .slick-list__top-container--tablet,
    .slick-list__top-container--mobile {
        .slick-arrow {
            &:hover {
                color: #FFB400;
            }
        }
    }

    .menu-item--desktop {
        &:hover {
            color: #FFB400;
        }
    }

    .header-menu-sub-item:hover {
        color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.4 HEADER LANGUAGE PICKER
|--------------------------------------------------------------------------
*/

.ll-language-picker--desktop,
.ll-language-picker--tablet,
.ll-language-picker--mobile {
    @extend %G-ll-language-picker;
    color: #fff;

    .ll-language-picker__wrapper {
        background: #1D2A2E;
    }

    .ll-dropdown-menu-item--active {
        .ll-language-picker__text {
            font-weight: bold;
        }
    }
}

.ll-language-picker--desktop {
    @extend %G-ll-language-picker--desktop;
    text-transform: uppercase;

    .ll-language-picker__wrapper {
        border: 1px solid #FFB400;
        border-radius: 14px;
        box-shadow: 0 0 1px 0 #FFB400 inset, 0 0 1px 0 #FFB400;
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        border: 1px solid #FFB400;
    }

    .ll-dropdown-menu-item {
        background: #1D2A2E;
    }

    .ll-dropdown-menu-item--active {
        background: #1D2A2E;
    }

    .ll-language-picker__items {
        :first-child {
            background: #1D2A2E;

            :after {
                background: #1D2A2E;
                border-right: 1px solid #FFB400;
                border-top: 1px solid #FFB400;
            }

            &.ll-dropdown-menu-item--active {
                :after {
                    border-right: 1px solid #FFB400;
                    border-top: 1px solid #FFB400;
                }
            }
        }
    }
}

.body--with-cursor {
    .ll-language-picker--desktop {
        .ll-dropdown-menu-item:hover,
        .ll-dropdown-menu-item--active:hover,
        .ll-language-picker__wrapper:hover {
            color: #FFB400;
        }
    }
}

.ll-language-picker--tablet,
.ll-language-picker--mobile {
    @extend %G-ll-language-picker--desktop;
    @extend %G-ll-language-picker--mobile;

    .ll-language-picker__wrapper {
        background: transparent;
        color: #fff;
    }

    .ll-dropdown-menu-item {
        border: 1px solid #22333A;
        background: #32484F;
    }

    .ll-dropdown-menu-item--active {
        border: 1px solid #22333A;
        background: #22333A;
        color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5. MODAL
|--------------------------------------------------------------------------
*/

.fullscreen-enabled {
    .ll-modal__scrollbox--desktop {
        max-width: 110vw;
    }
}

.ll-modal {
    @extend %G-ll-modal;
    background: #3D4F53;

    .ll-modal__header {
        background: #2F3F45;
        color: #FFB400;
    }

    .ll-modal__header--error {
        background: #c81c1c;
        color: #fff;
    }

    .ll-modal__btns--external {
        .ll-modal__button {
            background: #2F3F45;
            color: #FFB400;
        }
    }

    .ll-modal__button-inner {
        color: #BEBEBE;
    }

    .ll-modal__close-button {
        border-radius: 50%;
    }

    .ll-modal__fullscreen-button .ll-modal__button-inner {
        border-radius: 50%;
    }

    .ll-modal__custom-content {
        background: #3D4F53;
    }
}

.ll-modal__yes-no {
    @extend %G-modal__yes-no;
}

.ll-modal__external-mobile-wrp,
.ll-game-modal {
    @extend %G-ll-game-modal;
}

.ll-game-error-modal {
    @extend %G-ll-game-error-modal;
}

.body--with-cursor {
    .ll-modal__yes-no .ll-btn--outlined:hover {
        border-color: #fff;
        background: none;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.1 MODAL DESKTOP
|--------------------------------------------------------------------------
*/

.ll-modal__fullscreen-button {
    top: -3.2rem;
    right: 4.3rem;
}

.ll-modal.ll-modal--desktop {
    @extend %G-ll-modal--desktop;

    &.luckyland-link {
        max-width: 70vw;

        .ll-modal__scrollbox--external {
            width: 70vw;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.2 MODAL MOBILE
|--------------------------------------------------------------------------
*/

.ll-modal--mobile {
    @extend %G-ll-modal--mobile;

    .promo-modal {
        min-width: 80vw;
    }
}

.body--small-desktop {
    .ll-game-modal {
        @extend %G-ll-game-modal--small-desktop;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6. DRAWER
|--------------------------------------------------------------------------
*/

.ll-drawer {
    @extend %G-ll-drawer;
    background: #1D272E85;

    @supports (-webkit-backdrop-filter: blur()) {
        background: #1D272Eee;
    }

    .ll-drawer__header--mobile,
    .signup-drawer__header {
        background-color: #22333A;
        color: #FFB400;
    }

    .mobile-drawer__close-btn {
        color: #FFB400;
    }

    .drawer-menu {
        @extend %G-drawer-menu;
        color: #fff;

        .drawer-menu__item-icon {
            color: #fff;
        }

        .drawer-menu__item {
            border-bottom: 1px solid #22333A;
            color: #fff;
        }

        .menu-item__label {
            color: #fff;
        }
    }

    .ll-drawer__btn {
        background: #FFB400;
        color: #000;
    }

    .ll-drawer__btn.ll-btn--outlined {
        background: transparent;
        color: #FFB400;
        border-color: #FFB400;
    }

    .ll-input--focused,
    .ll-input--labeled-up {
        .ll-input__label {
            background: linear-gradient(180deg, transparent 55%, #3C535A 45%);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.1 GAME MENU DRAWER
|--------------------------------------------------------------------------
*/


/*
|--------------------------------------------------------------------------
| == 2.6.2 SUPPORT MENU DRAWER
|--------------------------------------------------------------------------
*/

.support-menu {
    @extend %G-support-menu;
    color: #fff;

    .llg-envelope-o,
    .ll-footer__social-icon {
        color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.3 MY ACCOUNT MENU DRAWER
|--------------------------------------------------------------------------
*/

.my-account-menu-drawer {
    @extend %G-my-account-menu-drawer;
    color: #fff;

    .my-account-menu-drawer__icon {
        color: #FFB400;
    }

    .my-account-menu-drawer__blocked-user {
        color: #FFB400;
    }

    .my-account-menu-drawer__text--accent {
        color: #F5782C;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.4 LOGIN DRAWER
|--------------------------------------------------------------------------
*/

.login-drawer {
    @extend %G-login-drawer;

    .login-drawer-menu {
        color: #fff;
    }

    .login-form__signup-link {
        color: #FFB400;
        text-decoration: underline;
    }

    .login-drawer-menu__item {
        background: #22333A;
        color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.7. POPOVER
|--------------------------------------------------------------------------
*/

.ll-popover {
    @extend %G-ll-popover;
}

.ll-popover-wrapper {
    @extend %G-ll-popover-wrapper;
}

.change-password__popover {
    @extend %G-change-password__popover;
}

.bet-history__status-filter-dropdown {
    @extend %G-bet-history__status-filter-dropdown;
}

.ll-footer__popover {
    @extend %G-ll-footer__popover;
}

.ll-footer__popover.ll-popover--tablet,
.ll-footer__popover.ll-popover--mobile {
    @extend %G-ll-footer__popover--tablet--mobile;

    background-color: #22333A;

    .ll-footer__popover-item {
        color: #fff;

        &:not(:last-of-type) {
            border-bottom: 1px solid #121A1D;
        }
    }
}

.ll-footer__popover.ll-popover--desktop {
    @extend %G-ll-footer__popover--desktop;

    .ll-footer__popover-content {
        background-color: #1D2B2F;
    }

    .ll-footer__popover-item {
        color: #fff;

        &:not(:last-of-type) {
            border-bottom: 1px solid #2e3b40;
        }
    }
}

.body--with-cursor {
    .ll-footer__popover-item:hover {
        color: #FFB400;
    }
}

.ll-popover__text {
    border: 1px solid #BEBEBE;
    border-radius: 3px;
    color: #000;
    background: #fff;
    padding: 0.3rem;
}

/*
|--------------------------------------------------------------------------
| == 2.8. BACKDROP
|--------------------------------------------------------------------------
*/

.ll-backdrop {
    @extend %G-ll-backdrop;

    background: transparent;
    transition: background 0.2s ease-out;

    &.ll-backdrop--open {
        background: rgba(0, 0, 0, 0.5);

        &.ll-backdrop--transparent {
            background: transparent;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == DISABLE OF USER SELECTS
|--------------------------------------------------------------------------
*/
.ll-dropdown-menu-item {
    user-select: none;
}

/*
|--------------------------------------------------------------------------
| == ACTIVE BETS & MY BETS DESKTOP POPOVER
|--------------------------------------------------------------------------
*/

.ll-popover-cashout_yes-no {
    @extend %G-popover-cashout_yes-no;

    .popup__text {
        font-size: 16px;
    }

    .popup__btns {
        .popup-btn {
            background: #ffb400;
            color: #000;

            &:hover {
                box-shadow: inset 0 0 1px 1px #ffb400;
                background: transparent;
                color: #ffb400;
            }
        }

        .popup-btn--loading {
            box-shadow: inset 0 0 1px 1px #ffb400;
            background: transparent;
        }

        .popup-btn-outlined {
            box-shadow: inset 0 0 1px 1px #ffb400;
            background: transparent;
            color: #ffb400;

            &:hover {
                background: #ffb400;
                color: #000;
            }
        }
    }

    .popup__btns--delete {
        .popup-btn {
            background: #ff0000;
            color: #fff;

            &:hover {
                box-shadow: inset 0 0 1px 1px #ff0000;
                background: transparent;
                color: #ff0000;
            }
        }

        .popup-btn-outlined {
            box-shadow: inset 0 0 1px 1px #ff0000;
            background: transparent;
            color: #ff0000;

            &:hover {
                background: #ff0000;
                color: #fff;
            }
        }
    }
}
