/*
4.FORMS
├── 4.1 LOGIN FORM
│   ├── 4.1.1 LOGIN FORM DESKTOP
│   └── 4.1.2 LOGIN FORM MOBILE
├── 4.2 FORGOT PASSWORD FORM
│   ├── 4.2.1 FORGOT PASSWORD MODAL FORM
│   └── 4.2.2 FORGOT PASSWORD PAGE FORM
├── 4.3 RESET PASSWORD FORM
│   ├── 4.3.1 RESET PASSWORD PAGE FORM
│   └── 4.3.2 RESET PASSWORD PAGE FORM
└── 4.4 CHANGE PASSWORD FORM
    ├── 4.4.1 CHANGE PASSWORD MODAL FORM
    └── 4.4.2 CHANGE PASSWORD PAGE FORM
*/

/*
| == 4.FORMS
|--------------------------------------------------------------------------
| == 4.1 LOGIN FORM
|--------------------------------------------------------------------------
| == 4.1.1 LOGIN FORM DESKTOP
|--------------------------------------------------------------------------
*/

.form__captcha-text {
    color: #888888;

    .form__captcha-link {
        color: #FFB400;
    }
}

.ll-login-form-modal {
    .login-form__close-btn:hover {
        background: #BEBEBE;
        color: #1D2A2E;
        opacity: 0.7;
    }

    .login-form__error {
        color: #f44336;
        text-align: center;
    }

    .login-form--desktop {
        @extend %G-login-form;
        @extend %G-login-form--desktop;
    }

    .ll-scrollbox--desktop {
        border-radius: 0 0 3px 3px;
    }
    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }
}

.body--with-cursor {
    .login-form__forgot-pwd-btn:hover,
    .login-form__signup-link:hover {
        color: #FFB400;
    }

    .login-form__login-btn:hover {
        background: #32484F;
        color: #FFB400;
        opacity: 1;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.1.2 LOGIN FORM MOBILE
|--------------------------------------------------------------------------
*/

.login-form--tablet,
.login-form--mobile {
    @extend %G-login-form;

    color: #fff;

    .login-form__error {
        color: #f44336;
        text-align: center;
    }

    .login-form__forgot-pwd-btn {
        text-align: center;
        font-size: 1.4rem;
        color: #FFB400;

        &:hover {
            text-decoration: underline;
        }
    }

    .dx-texteditor.dx-editor-outlined.dx-state-focused {
        -webkit-box-shadow: inset 0 0 0 2px #FFB40055 !important;
        box-shadow: 0 0 8px 1px #FFB40055 !important;
    }

    .login-form__close-btn,
    .login-form__login-btn {
        text-align: center;
        text-transform: uppercase;
    }
}

.login-drawer__logo {
    @extend %G-login-drawer__logo;

    background-image: url('../../../../static/themes/theme/images/logos/logo.png');
    background-size: cover;
}

/*
|--------------------------------------------------------------------------
| == 4.2 FORGOT PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.2.1 FORGOT PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

.forgot-password-form {
    @extend %G-forgot-password-form;
}

.ll-forgot-password-modal {
    .forgot-password-captcha--error,
    .forgot-password-captcha--error .dx-item-content {
        font-size: 1.2rem !important;
        color: red;
        text-align: center;
    }

    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }

    .ll-scrollbox--desktop {
        border-radius: 0 0 3px 3px;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.2.2 FORGOT PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.forgot-password--mobile,
.forgot-password--tablet {
    @extend %G-forgot-password--mobile;

    color: #fff;
    background-color: #1D2B2F;

    .forgot-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    label.ll-input__label--inside {
        background-color: #3C535A;
    }

    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }
}

.forgot-password--tablet {
    @extend %G-forgot-password--tablet;
}

/*
|--------------------------------------------------------------------------
| == 4.3 RESET PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.3.1 RESET PASSWORD FORM
|--------------------------------------------------------------------------
*/

.reset-password--desktop {
    @extend %G-reset-password--desktop;

    color: #fff;
    background: #1D2B2F;
    font-size: 0.9rem;

    .reset-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .reset-password__wrong-code {
        font-size: 1.2rem;
        text-align: center;
        color: #C73738;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.5rem;
    }

    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }
}

.body--with-cursor {
    .reset-password__submit-btn:hover {
        border: 1px solid #FFB400;
        background: transparent;
        color: #FFB400;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.3.2 RESET PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.reset-password--tablet,
.reset-password--mobile {
    @extend %G-reset-password--mobile;
    color: #fff;

    .reset-password__header {
        font-size: 1.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .reset-password__wrong-code {
        font-size: 1.2rem;
        text-align: center;
        background: #3D4F53;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.2em;
    }
    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }
}

.reset-password--tablet {
    @extend %G-reset-password--tablet;

    border: 1px solid #3D4F53;
    background: #3D4F53;
}

.reset-password--mobile {
    flex: 1;
}

/*
|--------------------------------------------------------------------------
| == 4.4 CHANGE PASSWORD FORM
|--------------------------------------------------------------------------
*/
.change-password {
    @extend %G-change-password;
}

/*
|--------------------------------------------------------------------------
| == 4.4.1 CHANGE PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

.modal-change-password {
    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }

    .change-password__form-container {
        color: #fff;
    }

    .change-password__header {
        font-size: 1.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .dx-invalid-message > .dx-overlay-content {
        font-size: 0.75em;
    }

    .password-strength__label {
        text-align: center;
        font-size: 1.8rem;
    }

    .password-strength__texts {
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4.2 CHANGE PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.change-password--tablet,
.change-password--mobile {
    @extend %G-change-password--mobile;
    color: #fff;
    background-color: #1D2B2F;

    .ll-input__text-box-container {
        &:focus-within{
            box-shadow: 0 0 8px 1px #FFB40055;
        }
    }

    .change-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.2em;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4.3(NEW) Google Authenticator
|--------------------------------------------------------------------------
*/

.google-auth__input {
    &:focus-within{
        box-shadow: 0 0 8px 1px #FFB40055;
    }
}