/*
3.PAGES
└── 3.10. HELP PAGE
*/

/*
|--------------------------------------------------------------------------
| == 3.10. HELP PAGE
|--------------------------------------------------------------------------
*/

.help {
    @extend %G-help;
    background: #3D4F53;
    color: #fff;
    width: 100%;
    max-width: 100%;

    .help__tile {
        background: url('../../../../static/themes/theme/images/help/live_help.png') no-repeat 50% 50%;
        background-size: contain;
        color: #fff;
        width: 345px!important;
    }

    .help__tile-text-container {
        padding-top: 0;
    }

    .help__tile-text__title,
    .help__tile-text {
        margin-bottom: 2rem!important;
    }
}

.help-mobile {
    @extend %G-help-mobile;
    padding: 0 1rem;

    .help__tile {
        width: 100%!important;
    }
}