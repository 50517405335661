.pre-match-details__market-group__custom-bets {
    background: #000;
}

.ll-custom-bets {
    .ll-custom-bets__info {
        background-color: #22333A80;
        color: #fff;
    }

    .ll-custom-bets__about-image {
        background-image: url("../../../../static/themes/theme/images/custom-bets/custom-bets.png");
    }

    .ll-custom-bets__title {
        color: #fff;
        background: #22333A;
    }

    .ll-custom-bets__about-terms-link {
        color: #FFB400;
    }

    .ll-custom-bets__market-groups {
        background-color: #1C1C1C;
    }

    .ll-custom-bets__open-close-info {
        color: white;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #324a53;
        color: #fff;
    }

    .ll-custom-bets-pre__market-group--active {
        color: #F5782C;
    }

    .match-details__market-header {
        background-color: #A1B4BA;
        color: #000;
    }

    .match-details__market {
        background-color: #22333A;
    }

    .match-details__bet--custom-unavailable {
        opacity: .5;
        cursor: not-allowed;
    }
}

.ll-scrollbox--tablet .ll-custom-bets,
.ll-scrollbox--desktop .ll-custom-bets {
    .match-details__bet {
        color: #000;
        background-color: #fff;

        &.match-details__bet--active {
            background: #FFB400;
            color: #000;

            .match-details__bet-value {
                color: #000;
            }
        }
    }
}

.ll-scrollbox--mobile .ll-custom-bets {
    @extend %G-custom-bets--mobile;

    .ll-custom-bets__about-text {
        padding-bottom: 3rem;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #1d2a2e;
    }

    .match-details__market-header {
        background-color: #233840;
        border: none;
        color: #fff;
    }

    .match-details__bets {
        background: #2F3F45;

        &:not(:last-of-type) {
            border-bottom: 2px solid #233840;
        }
    }

    .match-details__bet {
        background: #5B7177;
        color: #fff;

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -2px 0 0 0 #233840;
        }

        &.bet--column3 {
            box-shadow: inset -2px 0 0 0 #233840;
        }
    }

    .match-details__bet--active {
        background: #FFB400;
        color: #000;
    }
}

.body--with-cursor .ll-custom-bets {
    .ll-custom-bets__about-terms-link:hover {
        color: #fff;
    }

    .ll-custom-bets__open-close-info:hover {
        color: #FFB400;
    }

    .pre-match-details__market-group:hover {
        color: #F5782C;
        background: transparent;
    }

    .ll-custom-bets__bet-button:hover {
        background-color: #FFB400;
        color: #000;
    }

    .match-details__bet:not(.match-details__bet--disabled):hover {
        background: #FFB400;
        border-color: #FFB400;
        color: #000;
    }

    .ll-custom-bets__about-info-button:hover {
        color: #FFB400;
    }
}

.bet-builder-modal__info-header {
    color: #FFB400;
}

.ll-custom-bets__about-info-container {

    .ll-custom-bets__about-info-button {
        color: #FFFFFF;
    }

    .ll-custom-bets__about-info-content-container {
        color: #ffffff;
        background-color: #22333A;
        border-color: #2F3F45;
    }

    .ll-custom-bets__about-info-close {
        color: #BEBEBE;
    }
}
